import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import { TFC_GEO_REGIONS } from '../utils/constants.ts';
export default class LinkToTfcResource extends Helper {
  @service('terraform-unification') terraformUnification;

  compute([
    geo = TFC_GEO_REGIONS.REGION_US,
    resourceType = null,
    terraformOrganizationName = null,
    projectId = null,
    resourceId = null,
  ]) {
    const url = this.terraformUnification.getDeploymentUrl({ geo });
    if (!url) {
      return;
    }
    return this.terraformUnification.getResourceUrl({
      url,
      resourceType,
      resourceIdentifiers: {
        terraformOrganizationName,
        projectId,
        resourceId,
      },
    });
  }
}
